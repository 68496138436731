.carousel-indicators {
    bottom: 20%;
}
.item-carousell{
    background-size: cover;
    background-color: white!important;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    transition: all ease-in .2s;
}
.flex-transform{
    background-color: violet;
    height: 100%;
    width: 100%;
    display: flex;
    background-size: cover;
    background-color: white!important;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    transition: all ease-in .2s;
}
.item-carousell-1{
    background-image: linear-gradient(to bottom,#00000080, #9198e500),url(./../../resources/images/car1.jpg);
    justify-content: flex-end;
    align-items: flex-end;
}
.item-carousell-2{
    background-image: linear-gradient(to bottom,#00000080, #9198e500),url(./../../resources/images/car2.jpg);
    justify-content: flex-end;
    align-items: flex-end;
}
.item-carousell-3{
    background-image: linear-gradient(to bottom,#00000080, #9198e500),url(./../../resources/images/car4.jpg);
    justify-content: flex-end;
    align-items: flex-start;
}
.item-carousell-4{
    background-image: linear-gradient(to bottom,#00000080, #9198e500),url(./../../resources/images/car3.jpg);
    justify-content: flex-end;
    align-items: flex-start;
}
.item-carousell-5{
    background-image: linear-gradient(to bottom,#00000080, #9198e500),url(./../../resources/images/car5.jpg);
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;
}
.carousel-inner{
    height: 100%;
}
.carousel{
    height: 100%;
    width: 100%;
    background-color: sandybrown;
}

.carousel-text-container-2{
    background-image: linear-gradient(to top,#000000, #00000000);
    justify-content: flex-end;
    height: 70%;
    width: 500px;
    margin-right: 10%;
    padding: 7% 20px ;
    text-align: left;
}
.carousel-text-container-3{
    background-image: linear-gradient(to top,#000000, #00000000);
    justify-content: flex-end;
    height: 70%;
    width: 500px;
    margin-left: 10%;
    padding: 7% 20px ;
    text-align: left;
}
.carousel-text-container-4{
    background-image: linear-gradient(to top,#000000, #00000000);
    justify-content: flex-end;
    height: 70%;
    width: 500px;
    margin-left: 10%;
    padding: 3% 20px ;
    text-align: left;
}
.carousel-text-container-5{
    background-image: linear-gradient(to top,#000000, #00000000);
    justify-content: flex-end;
    height: 70%;
    width: 500px;
    margin-left: 10%;
    padding: 7% 20px ;
    text-align: left;
}
.carousel-header{
    color: white;
    font-size: 5em;
    font-family: AvenirNextBold;
    margin-bottom: .5em;
}
.supply-image{
    width: 600px;
    justify-self: center;
    align-self: center;
    margin-top: 15em;
}
.exporter-image{
    width: 700px;
    margin-top: 15em;
    margin-bottom: 15em;
    margin-left: 25%;
}
.no-margin{
    margin: 0;
}
.carousel-text-2{
    color: white;
    font-size: 2.8em;
    font-family: AvenirNextRegular;
}
.carousel-text-4{
    color: white;
    font-size: 2.2em;
    font-family: AvenirNextRegular;
}
.carousel-text-5{
    color: white;
    font-size: 2.2em;
    font-family: AvenirNextRegular;
}

@media only screen and (max-width: 480px){
    .item-carousell-4,
    .item-carousell-3,
    .item-carousell-1{
        justify-content: flex-end;
        align-items: center;
    }
    .carousel-text-container-3,
    .carousel-text-container-4,
    .carousel-text-container-5,
    .carousel-text-container-2{
        justify-content: flex-end;
        height: 70%;
        width: 100%;
        text-align: left;
        margin-right: 0;
        margin-left: 0;
    }
    .carousel-header{
        font-size: 3em;
        margin-bottom: .5em;
    }
    .carousel-text-2{
        font-size: 2em;
    }
}


.container{
    height: 100vh;
    width: 100%;
    padding: 0;
}
.section-1{
    display: flex;
    width: 100%;
    min-height: 110%;
    background: linear-gradient(to bottom,#00000080, #9198e500),url('./../../resources/images/section1.jpg');
    background-position: center;
    background-size:cover;
    background-attachment: fixed;
}


.carousell-section-1{
    background-color: wheat;
    max-height: 100vh;
}

.back-image-carousel{
    width: 100%;
}


.slide-1{
    background-image: url(./../../resources/images/car1.jpg);
    background-position: center;
    background-size: cover;
    height: 100%;
}
.slide-2{
    background-image: url(./../../resources/images/car2.jpg);
    background-position: center;
    background-size: cover;
    height: 100%;
}

.slider-section{
    background-color: firebrick;
    height: 50%;
}
.section-2{
    background-color: rgb(255, 255, 255);
    min-height: 110vh;
    display: flex;
    margin-top: -5%;
    padding-top: 5%;
    padding-bottom: 5%;
    justify-content: center;
    -webkit-clip-path: polygon(0 10vh, 100% 0, 100% 100%,0 100%);
    clip-path: polygon(0 10%, 100% 0, 100% 100%,0 100%);
    overflow: hidden;
}

.section-2-left{
    width: 50%;
    min-width:50%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.section-2-right{
    width: 50%;
    height: 100%;
    min-width:50%;
}

.section-2-inner-container{
    width: 80%;
    height: 90%;
    text-align: justify;
    text-justify: inter-word;
    padding-top: 10%;
    margin-right: 15px;

    backface-visibility: hidden;
}

.section-2-header{
    margin: 0px;
    margin-bottom: 10px;
    color: #ffc000;
    font-size: 5em;
    font-family: AvenirNextBold;
}

.section-2-second-header{
    margin: 0;
    margin-top: -.6em;
    font-size: 3em;
    font-family: AvenirNextRegular;
}

.section-2-subheader{
    font-size: 2em;
    margin: 0;
    margin-bottom: 0;
}

.section-2-header-container{
    width: 60%;
    min-width: 450px;
    border-right:solid black 5px;
    padding-bottom: 1em;
    margin-bottom: 2em;
    backface-visibility: hidden;
}
.section-2-text-container{
    max-width: 700px;
    margin-top: 0;
    margin-bottom: 2em;
    font-size: 1.5em;
}
.section-2-image{
    margin-top: -5%;
    width: 120%;
    -webkit-clip-path: polygon(0 10vh, 100% 0, 100% 100%,0 100%);
    clip-path: polygon(0 8%, 100% 0, 100% 92%,0 100%);
}
@media only screen and (max-width: 1800px) {
    .section-2-header{
        font-size: 4em;
    }
    .section-2-subheader{
        font-size: 1.7em;
    }
    .section-2-header-container{
        min-width: 350px;
        margin-bottom: 1em;
    }
    .section-2-text-container{
        font-size: 1.4em;
        max-width: 600px;
    }
    .section-2-image{
        margin-top: -5%;
        width: 140%;
        -webkit-clip-path: polygon(0 10vh, 100% 0, 100% 100%,0 100%);
        clip-path: polygon(0 8%, 100% 0, 100% 92%,0 100%);
    }
    .section-2-inner-container{
        width: 90%;
        height: 90%;
        text-align: justify;
        text-justify: inter-word;
        padding-top: 10%;
        margin-right: 15px;
    }
}
@media only screen and (max-width: 480px){
    .section-2{
        min-height: 100%;
        display: flex;
        flex-direction: column;
        margin-top: -10%;
        padding-top: 5%;
        padding-bottom: 5%;
        -webkit-clip-path: polygon(0 5vh, 100% 0, 100% 100%,0 100%);
        clip-path: polygon(0 2.5%, 100% 0, 100% 100%,0 100%);
    }
    .section-2-header{
        font-size: 3em;
    }
    .section-2-header-container{
        margin-bottom: 1em;
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
    .section-2-header{
        width: 100%;
        text-align: center;
    }
    .section-2-inner-container{
        width: 100%;
        padding-top: 10%;
        margin-right: 0;
    
    }
    .section-2-left{
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
    .section-2-second-header{
        margin: 0;
        margin-top: -.6em;
        font-size: 2em;
        font-family: AvenirNextRegular;
        width: 100%;
        text-align: center;
    }
    .section-2-text-container{
        font-size: 1.2em;
        padding: 15px;
    }
    .section-2-subheader{
        font-size: 1.5em;
        padding: 15px;
    }
    .section-2-right{
        width: 100%;
        height: 100%;
        min-width:50%;
    }
}

.section-3{
    background-color: #232323;
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    color: white;
    justify-content: center;
    align-items: center;
    -webkit-clip-path: polygon(0 10vh, 100% 0, 100% 90vh,0 100%);
    clip-path: polygon(0 10vh, 100% 0, 100% 90vh,0 100%);
    margin-top: -7%;
    text-align: justify;
    text-justify: inter-word;
}

.section-3-text-container{
    width: 90%;
    max-width: 1200px;
    font-size: 1.9em;
    margin-bottom: 20px;
    font-family: Book Antigua;
}
.section-3-header{
    font-size: 5em;
    border-right: solid white 5px;
    margin-bottom: 1em;
    padding-right: .5em;
    font-family: AvenirNextBold;
}

@media only screen and (max-width: 1800px) {
    .section-3-header{
        font-size: 3em;
        margin-bottom: .8em;
    }
    .section-3-text-container{

        font-size: 1.6em;
    }
}
@media only screen and (max-width: 480px){
    .section-3{
        -webkit-clip-path: polygon(0 20px, 100% 0, 100% 100%,0 100%);
        clip-path: polygon(0 20px, 100% 0, 100% 97.5%,0 100%);
        margin-top: -10%;
        text-align: justify;
        text-justify: inter-word;
    }
    .section-3-header{
        margin-top: 15%;
        font-size: 2.5em;
        margin-bottom: .8em;
    }
    .section-3-text-container{
        font-size: 1.2em;
        padding: 15px;
    }
}

.section-4{
    background-color: #685723;
    min-height: 100vh;
    display: flex;
    color: white;
    margin-top: 0%;
    -webkit-clip-path: polygon(0 10vh, 100% 0, 100% 100%,0 100%);
    clip-path: polygon(0 10vh, 100% 0, 100% 100%,0 100%);
}

.section-4-right{
    background-color: #232323;
    width: 50%;
    min-width:50%;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.section-4-left{
    background-color: #ffc000;
    width: 50%;
    min-width: 50%;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}
.section-4-header{
    font-size: 5em;
    margin-bottom: .7em;
    font-family: AvenirNextBold;
}
.section-4-image-container{
    background: url('./../../resources/images/Tomas.png');
    background-size: cover ;
    background-position: center;
    width: 600px;
    height: 600px;
    -webkit-clip-path: polygon(0 5%, 100% 0, 100% 90%,0 100%);
    clip-path: polygon(0 7%, 100% 0, 100% 95%,0 100%);
    margin-top: 9vh;
}
.section-4-inner-left{
    width: 90%;
    max-width: 600px;
    text-align: justify;
    text-justify: inter-word;
}
.section-4-name{
    font-size: 1.9em;
}
.section-4-position{
    font-size: 1.5em;
}
.section-4-text{
    font-size: 1.3em;
    margin-bottom: 10px;
    color: black;
    max-width: 800px;
    font-family: Book Antigua;
}

@media only screen and (max-width: 1800px) {
    .section-4-header{
        font-size: 3.5em;
        margin-bottom: .4em;
        letter-spacing: .1em;
    }
    .section-4-text{
        font-size: 1.2em;
        color: rgb(49, 48, 48);
        max-width: 550px;
    }
    .section-4-image-container{
        width: 500px;
        height: 500px;
    }
    .section-4-name{
        font-size: 1.6em;
    }
    .section-4-position{
        font-size: 1.4em;
    }
}
@media only screen and (max-width: 1000px) {
    .section-4-image-container{
        width: 400px;
        height: 400px;
    }
}
@media only screen and (max-width: 480px){
    .section-4{
        background-color: #685723;
        min-height: 100%;
        display: flex;
        flex-direction: column;
        color: white;
        margin-top: 0%;
        -webkit-clip-path: polygon(0 20px, 100% 0, 100% 100%,0 100%);
        clip-path: polygon(0 20px, 100% 0, 100% 100%,0 100%);
    }
    .section-4-left{
        width: 100%;
        min-width: 100%;
    }
    .section-4-right{
        width: 100%;
        min-width:100%;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }
    .section-4-header{
        margin-top: 1em;
        font-size: 3em;
        margin-bottom: .7em;
        font-family: AvenirNextBold;
    }
    .section-4-image-container{
        width: 350px;
        height: 480px; 
        margin-top: 9vh;
    }
    .section-4-position{
        margin-bottom: 3em;
    }
}

.quote-separator{
    min-height: 40%;
    background-color: rgb(0, 0, 0);
    color: white;
    display: flex;
    justify-content: center;
    -webkit-clip-path: polygon(0 10vh, 100% 0, 100% 90%,0 100%);
    clip-path: polygon(0 10vh, 100% 0, 100% 75%,0 100%);
    margin-top: -7.5%;
}
.quote-separator-text{
    position: relative;
    font-size: 1.7em;
    margin-top: 5em;
    margin-bottom: 5em;
    max-width: 800px;
    font-family: Book Antigua;
}
.quote-separator-text:before {
    content: "\201C";
    font-family: Georgia, serif;
    position: absolute;
    font-size: 3em;
    line-height: 1;
    top: 0;
    left: 0;
    transform: translateY(-10%) translateX(-150%);
    color: azure;
}
.quote-separator-text:after {
    content: "\201D";
    font-family: Georgia, serif;
    position: absolute;
   /* display: block; don't use this, it raised the quote too high from the bottom - defeated line-height? */
    float:right;
    font-size:3em;
    line-height: 1;
    right:0;
}
@media only screen and (max-width: 1800px){
    .quote-separator{
        min-height: 35%;
        color: white;
        display: flex;
        justify-content: center;
        -webkit-clip-path: polygon(0 10%, 100% 0, 100% 90%,0 100%);
        clip-path: polygon(0 25%, 100% 0, 100% 75%,0 100%);
        margin-top: -7.5%;
    }
    .quote-separator-text{
        font-size: 1.5em;
        margin-top: 3em;
        margin-bottom: 3em;
        max-width: 800px;
    }

}
@media only screen and (max-width: 900px){
    .quote-separator-text:before {
        transform: translateY(-30px);
    }
}
@media only screen and (max-width: 480px){
    .quote-separator{
        min-height: 35%;
        background-color: black;
        color: white;
        display: flex;
        justify-content: center;
        -webkit-clip-path: polygon(0 20px, 100% 0, 100% calc(100% - 20px), 0 100%);
        clip-path: polygon(0 20px, 100% 0, 100% calc(100% - 20px),0 100%);
        margin-top: -7.5%;
    }
    .quote-separator-text{
        font-size: 1.3em;
        padding: 20px;
        margin-top: 3em;
        max-width: 800px;
    }
    .quote-separator-text:before {
        transform: translateY(-10px) translateX(15px);
    }
    .quote-separator-text:after {
        transform: translateY(10px) translateX(0px);
    }
}
.section-5{
    min-height: 105vh;
    display: flex;
    color: #505050;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    -webkit-clip-path: polygon(0 10%, 100% 0, 100% 90%,0 100%);
    clip-path: polygon(0 10%, 100% 0, 100% 90%,0 100%);
    margin-top: -5%;
}

.section-5-section-limiter{
    min-height: 100%;
    width: 100%;
    max-width: 1700px;
    display: flex;
    flex-direction: column;
}

.section-5-header-containers{
    margin-top: 3em;
    margin-bottom: 3em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.section-5-header{
    font-size: 5em;
    padding-right: .5em;
    border-right: #505050 solid 5px;
    font-family: AvenirNextBold;
    margin-bottom: 0;
    margin-left: -.3em;
}
.section-5-subheader{
    margin-top: 0;
    font-size: 2em;
    text-transform: uppercase;
    font-family: AvenirNextRegular;
}
.section-5-info-container{
    display: flex;
    width: 100%;
}

.info-wrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

}
.section-5-info-left{
    min-width: 50%;
    flex: 1;
    display: flex;
    justify-content: center;
}
.section-5-info-right{
    min-width: 50%;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}
.section-5-icon{
    width: 100px;
    min-height: 90px;
    margin-bottom: 15px;
}

.section-5-info-big-text>a{
    color: black;
    font-size: 1.4em;
    margin: 25px;
    margin-left: 15px;
    margin-right: 15px;
    font-family: Book Antigua;
}
.section-5-info-big-text>a:hover{
    text-decoration: none;
}

.section-5-info-text{
    font-size: 1.2em;
    margin: 25px;
    margin-left: 15px;
    margin-right: 15px;
    font-family: Book Antigua;
}

.section-5-link>a{
    color: #a07800;
    font-size: 1.2em;
    margin: 0 0 0 0;
    font-family: Book Antigua;
}
.section-5-link>a:hover{
    text-decoration: none;
}

.bold{
    font-weight: bolds;
    font-size: 1.5em;
    color: black;
    margin-bottom: 5px;
}

@media only screen and (max-width: 1800px){
    .section-5-header{
        font-size: 3em;
    }
    .section-5-subheader{
        margin-top: 0;
        font-size: 1.5em;
    }
    .section-5-info-text{
        font-size: 1.1em;
    }
    .section-5-icon{
        width: 90px;
        
    }
    .section-5-section-limiter{
        max-width: 1200px;
        display: flex;
        flex-direction: column;
    }
}
@media only screen and (max-width: 480px){
    .section-5{
        -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%,0 100%);
        clip-path: polygon(0 0, 100% 0, 100% 100%,0 100%);
    }
    .section-5-info-container{
        flex-direction: column;
    }
    .section-5-link{
        margin-bottom: 3em;
    }
}
.section-6{
    background: url('./../../resources/images/groupimg.jpg');
    box-shadow: inset 0 0 0 1000px rgba(0,0,0,.4);
    background-size: cover ;
    background-position: center;
    background-attachment: fixed;
    width: 100%;
    min-height: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    -webkit-clip-path: polygon(0 25%, 100% 0, 100% 100%,0 100%);
    clip-path: polygon(0 25%, 100% 0, 100% 100%,0 100%);
    margin-top: -5%;
}

.section-6-text{
    color: white;
    max-width: 1000px;
    font-size: 2.5em;
    margin: 5% 0 20px 0;
    font-family: Book Antigua;
}

.section-6-link{
    color: #ffc000;
    font-size: 2em;
    margin: 0 0 7% 0;
    font-family: Book Antigua;
}
.section-6-link:hover{
    color: #ffc000;
}


@media only screen and (max-width: 1800px){
    .section-6-text{
        max-width: 1000px;
        font-size: 1.3em;
        margin: 5% 0 0 0;
    }
    .section-6-link{
        font-size: 1.6em;
        margin: 0 0 7% 0;
    
    }
}
@media only screen and (max-width: 480px){
    .section-6{
        -webkit-clip-path: polygon(0 20px, 100% 0, 100% 100%,0 100%);
        clip-path: polygon(0 20px, 100% 0, 100% calc(100% - 20px),0 100%);
        padding: 1em;
    }
}
.section-7{
    background-color: #f2f2f2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: -7%;
    padding-top: 7%;
    -webkit-clip-path: polygon(0 10vh, 100% 0, 100% 90%,0 100%);
    clip-path: polygon(0 10vh, 100% 0, 100% 100%,0 100%);
}

.section-7-header-container{
    display: flex;
    justify-content: flex-start;
    margin-bottom: 4em;
    margin-top: 13em;
}

.section-7-header{
    border-right: #5a5a5a solid 5px;
    font-size: 5em;
    padding-right: 1em;
    color: #505050;
    font-family: AvenirNextBold;
}

.section-limiter{
    min-height: 100%;
    width: 90%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    margin-bottom: 1em;
}
.sl-row{
    flex-direction: row;
}
.section-7-info{
    font-size: 1.2em;
    max-width: 1200px;
    align-self: center;
    color: #505050;
    margin: 0;
    font-family: Book Antigua;
}
.photo-row{
    width: 100%;
    max-width: 1400px;
    display: flex;
    justify-content: space-around;
    margin: 1em 0 1em;
}
.section-7-photo-row-section{
    background-color: #383838;
    width: 100%;
    min-height: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1%;
}
.inner-photo-section{
    margin-top: 5%;
    margin-bottom: 5%;
    width: 90%;
    height: 90%;
    background: linear-gradient(110deg, rgba(67,81,119,1) 0%, rgb(88, 51, 68) 100%);
    /*background: linear-gradient(110deg, rgba(120,96,73,0.75) 0%, rgb(196, 162, 130, 0.75) 100%); */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.inner-photo-section-header{
    color: white;
    font-family: AvenirNextUlL;
    font-size: 3em;
    text-transform: uppercase;
    margin-top: 1em;
}
@media only screen and (max-width: 1800px){
    .section-7-header{
        font-size: 3.5em;
    }
    .section-7-info{
        font-size: 1.2em;
    }
    .inner-photo-section-header{
        font-size: 2em;
    }
    .photo-row{
        flex-wrap: wrap;
    }
}
@media only screen and (max-width: 480px){
    .section-7{
        padding-top: 0;
        -webkit-clip-path: polygon(0 20px, 100% 0, 100% 100%,0 100%);
        clip-path: polygon(0 20px, 100% 0, 100% 100%,0 100%);
    }
    .section-7-header-container{
        margin-bottom: 1em;
        margin-top: 5em;
        padding: 15px;
    }
    .section-7-info{
        padding: 15px;
    }
    .photo-row{
        flex-wrap: wrap;
    }
}

.section-8{
    display: flex;
    flex-direction: column;
    align-items: center;
    background: url('./../../resources/images/section9.jpg');
    box-shadow: inset 0 0 0 1000px rgba(0,0,0,.4);
    background-size: cover ;
    background-position: center;
    background-attachment: fixed;
    min-height: 100vh;
    height: auto;
}
.section-8-no-photo{
    display: flex;
    flex-direction: column;
    align-items: center;
    background: url('./../../resources/images/section9.jpg');
    box-shadow: inset 0 0 0 1000px rgba(0,0,0,.4);
    background-size: cover ;
    background-position: center;
    background-attachment: fixed;
    min-height: 60vh;
    height: auto;
}

.section-8-header{
    margin-top: 2%;
    margin-bottom: 3%;
    font-size: 4em;
    font-family: AvenirNextBold;
    color: #ffc000;
}

.advisor-row{
    width: 100%;
    min-height: 70vh;
    display: flex;
    flex-direction: column;
}
.advisors-left{
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.advisors-right{
    width: 100%;
    min-height: 40vh;
    margin-top: 2%;
}
.advisors-right-inner{
    background-color: #ffffffd8;
    min-height: 35vh;
    color: rgb(0, 0, 0);
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;
    max-width: 1400px;
    margin: 0 auto;
}

.advisors-right-inner-text{
    font-size: 1.3em;
    margin-bottom: 20px;
}
.white-separator{
    height: 10%;
}
.grey-separator{
    height: 5%;
    background-color: #3f3f3f;
}
@media only screen and (max-width: 480px){
    .section-8-header{
        margin-top: 2%;
        margin-bottom: 3%;
        font-size: 2.5em;
        font-family: AvenirNextBold;
        color: #ffc000;
    }
}
.section-9{
    min-height: 80%;
    width: 100%;
    background: url('./../../resources/images/groupimg.jpg');
    box-shadow: inset 0 0 0 1000px rgba(0,0,0,.4);
    background-size: cover ;
    background-position: center;
    background-attachment: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
}
.section-9-header-container{
    width: 33%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
.section-9-logo{
    width: 300px;
    height: auto;
}
.section-9-logo-container{
    width: 100%;
    max-width: 66%;
    display: flex;
    margin-top: 5%;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;

}
.section-9-header{
    color: white;
    font-size: 4em;
    margin: 0;
    font-family: AvenirNextBold;
}

.section-9-text{
    color: white;
    font-size: 1.3em;
    max-width: 600px;
    margin: 0;
    margin-top: 10px;
    font-family: Book Antigua;
    text-align: center;
}
.section-9-logo-container-single{
    border-radius: 15px;
    min-width: 30%;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 50px;
}

.separator{
    color: white;
    background-color: white;
    width: 300px;
    height: 3px;
}
@media only screen and (max-width: 1800px){
    .section-9-header{
        font-size: 3em;
    }
    .separator{
        width: 200px;
        height: 3px;
    }
    .section-9-text{
        font-size: 1em;
        max-width: 600px;
        margin: 0;
        margin-top: 10px;
    }
    .advisors-right-inner-text{
        font-size: 1em;
        margin-bottom: 15px;
    }
}
@media only screen and (max-width: 480px){

    .section-9{
        background: url('./../../resources/images/groupimg.jpg');
        box-shadow: inset 0 0 0 1000px rgba(0,0,0,.4);
        background-size: cover ;
        background-position: center;
        background-attachment: none;
        flex-direction: column;
    }
    .section-9-header-container{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        padding: 15px;
        margin-top: 2em;
    }
    .section-9-logo-container{
        width: 100vw;
        max-width: none;
        display: flex;
        margin-top: 0;
        justify-content: center;
        align-items: center;
    }
    .section-9-logo{
        width: 100%;
        height: auto;
    }
    .section-9-logo-container-single{
        width: 80%;
        border-radius: 15px;
        min-width: 70%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 5%;
    }
}
.section-10{
    height: 50%;
    width: 100%;
    background-color: #2b2b2b;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.section-9-logo-container-single:hover{
    background-color: rgba(0, 0, 0, 0.5);
    cursor: pointer;
}

@media only screen and (max-width: 1800px){
    .section-10{
        height: 30%;
    }
}

.group-pop{
    overflow: hidden;
}

.group-pop-inner{
    width: 60vh;
    padding: 15px;
    display: flex;
    flex-direction: column;
}
.pop-link{
    align-self: center;
}
@media only screen and (max-width: 480px){
    .group-pop-inner{
        width: 40vh;
    }
}

@keyframes moveInLeft{
    0%{
        opacity: 0;
        transform: translateX(-300px);
    }
    100%{
        opacity: 1;
        transform: translateX(0);
    }
}
@keyframes moveInRight{
    0%{
        opacity: 0;
        transform: translateX(300px);
    }
    100%{
        opacity: 1;
        transform: translateX(0);
    }
}

.test{
    height: 100vh;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
}


.Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4);
}
.ReactModalPortal{
    z-index: 200;
}

.Modal {
    position: absolute;
    top: 20%;
    left: 25%;
    right: 25%;
    bottom: 20%;
    
    background-image: linear-gradient(#00000036, #000000e3, #00000036),url(./../../resources/images/popupcovid.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 200;
}

.Modal-close {
    padding: 5px;
}

.Modal-close-container {
    display: flex;
    justify-content: flex-end;
}

.Modal-header-container{
    background: linear-gradient(to right,#00000026, #000000d3);
    display: flex;
    justify-content: flex-end;
}

.Modal-msg-container{
    width: 100%;
}

.Modal-btn-container{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.Modal-text {
    font-family: AvenirNextRegular;
}

.Modal-textBold {
    font-family: AvenirNextBold;
}

.Modal-header {
    color: #95ba5d;
    font-size: 3em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    padding-right: 1em;
}

.Modal-msg {
    color: #ffffff;
    font-size: 1.25em;
    padding: 2em;
}

.Modal-btn-msg {
    color: #ffffff;
    font-size: 1.1em;
    width: 20%;
    margin-right: 5%;
}

.Modal-btn {
    width: 45%;
    filter: grayscale(20%);
}

.Modal-btn:hover {
    filter: none;
}

@media only screen and (max-width: 1400px){
    .Modal {
        left: 15%;
        right: 15%;
    }
}

@media only screen and (max-width: 1000px){
    .Modal {
        left: 5%;
        right: 5%;
        top: 15%;
        bottom: 15%;
    }
}

@media only screen and (max-width: 480px){
    .Modal {
        left: 5%;
        right: 5%;
        top: 10%;
    bottom: 10%;
    }
    .Modal-btn-container{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }
    .Modal-btn-msg {
        width: 80%;
        font-size: 1em;
    }
    
    .Modal-btn {
        width: 80%;
    }
    .Modal-header {
        font-size: 1.3em;
    }
    .Modal-msg {
        font-size: 1em;
        padding: 0;
    }
}