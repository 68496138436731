.singleAdvisor{
    width: 100%;
    height: 300px;
    display: flex;
    margin-bottom: 1.5em;
    margin-top: 1.5em;

}

@media only screen and (max-width: 1800px){
    .singleAdvisor{
        height: 250px;
    }
}

.row-reverse{
    flex-direction: row-reverse;
}

.area-space{
    width: calc((100% - 1100px)/2);
}

.photo-container{
    height: 100%;
    width: auto;
    background-color: deepskyblue;
    display: flex;
}

.single-advisor-area{
    background-color: #5b85a6;
    display: flex;
    height: 100%;
    flex: 1;
}
.text-container{
    max-width: 900px;
    color: white;
    margin-left: 1em;
    margin-right: 1em;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.advisor-photo{
    height: 100%;
    width: 100%;
}

.single-advisor-name{
    font-family: AvenirNextRegular;
    font-size: 1.5em;
    margin-bottom: 15px;
}

.single-advisor-description{
    font-family: Book Antigua;
}