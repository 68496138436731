.singlePhoto-container{
    width: 210px;
    display: flex;
    flex-direction: column;
    transition: all .1s;
}

.singlePhoto-container.selected{
    width: 210px;
    display: flex;
    flex-direction: column;
    box-shadow: 0 10px 20px rgba(0, 0, 0, .9);
}

.singlePhoto-container:hover{
    width: 210px;
    display: flex;
    flex-direction: column;
    box-shadow: 0 10px 20px rgba(0, 0, 0, .5);
    transition: all .1s;
}

.single-name{
    color: #5a5a5a;
    margin: 0;
    font-size: .9em;
    font-family: AvenirNextBold;
}

.single-position{
    color: #5a5a5a;
    margin: 0;
    font-size: .8em;
    font-family: AvenirNextRegular;
}

.single-photo{
    width: 100%;
}


.singlePhoto-text-container{
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 2em;
    padding-bottom: 2em;
    padding-right: 10px;
    padding-left: 10px;
    flex: 1;
}
.singlePhoto-see-more{
    margin-top: 10px;
    margin-bottom: 10px;
    border: #b0b0b0 solid 2px;
    padding: 3px 7px;
    border-radius: 15px;
    font-size: 12px;
}

.singlePhoto-icon-container{
    width: 85%;
    display: flex;
    justify-content: space-around;
    margin-top: 15px;
}
.singlePhoto-icon{
    height: 100%;
}

@media only screen and (max-width: 480px){
    .singlePhoto-container{
        margin-bottom: 2em;
        width: 45%;
    }
}