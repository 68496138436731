*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
body {
    background-color: rgb(223, 223, 223);
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin:0;    
    padding:0;
}

html {
    height: 100%;
    text-align: justify;
    text-justify: inter-word;
    scroll-behavior: smooth;
}

.general-layout{
    max-width: 100%;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    background-color: brown;
}
.section-layout{
    width: 100%;
    min-height: 100%;
    max-width: 100vw;
    overflow: hidden;
}