.advisor-mini-image{
    width: 200px;
    margin-bottom: 5px;
}

.advisor-mini-container{
    color: black;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: 15px;
    cursor: pointer;
    padding: 5px;
    border-radius: 4px;
    filter: grayscale(80%);
}
.advisor-mini-container:hover{
    -webkit-box-shadow: 4px 4px 27px -19px rgba(0,0,0,0.75);
    -moz-box-shadow: 4px 4px 27px -19px rgba(0,0,0,0.75);
    box-shadow: 4px 4px 27px -19px rgba(0,0,0,0.75);
    filter: none;
}

.advisor-mini-container.selected{
    -webkit-box-shadow: 4px 4px 27px -11px rgba(0,0,0,0.75);
    -moz-box-shadow: 4px 4px 27px -11px rgba(0,0,0,0.75);
    box-shadow: 4px 4px 27px -11px rgba(0,0,0,0.75);
    filter: none;
}

.advisor-mini-text-1{
    max-width: 178px;
    font-size: 2em;
    word-break: break-word;
    margin: 0;
    font-family: AvenirNextRegular;
    color: white;
    margin-top: 5px;
}

.advisor-mini-text-2{
    max-width: 178px;
    font-size: 2em;
    margin: 0;
    color: #ffc000;
    margin-top: -10px;
    font-family: AvenirNextRegular;
    margin-bottom: 5px;
}

.mini-advisor-image-container{
    display: flex;
    background-color: rgb(0, 0, 0);
    width: 200px;
    height: 200px;
}

.mini-advisor-image-container.adv-min-0{
    background-image:url(./../../../resources/images/Advisors/advisor-1.png);
    background-size: cover;
}
.mini-advisor-image-container.adv-min-1{
    background-image:url(./../../../resources/images/Advisors/advisor-2.png);
    background-size: cover;
}
.mini-advisor-image-container.adv-min-2{
    background-image:url(./../../../resources/images/Advisors/advisor-3.png);
    background-size: cover;
}
.mini-advisor-image-container.adv-min-3{
    background-image:url(./../../../resources/images/Advisors/advisor-4.png);
    background-size: cover;
}
.mini-advisor-image-container.adv-min-4{
    background-image:url(./../../../resources/images/Advisors/advisor-5.png);
    background-size: cover;
}

.mini-adisor-name-container{
    background-color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    border-top: solid #ffc000 3px;
    border-bottom: solid #ffc000 3px;
}

@media only screen and (max-width: 1800px){
    .advisor-mini-image{
        width: 150px;
    }
    .advisor-mini-text{
        max-width: 140px;
        word-break: break-word;
    }
    .advisor-mini-text-1{
        max-width: 178px;
        font-size: 1.5em;
        word-break: break-word;
        margin: 0;
        font-family: AvenirNextRegular;
        color: white;
        margin-top: 5px;
    }
    .advisor-mini-text-2{
        max-width: 178px;
        font-size: 1.5em;
        margin: 0;
        color: #ffc000;
        margin-top: -10px;
        font-family: AvenirNextRegular;
        margin-bottom: 5px;
    }
    .mini-advisor-image-container{
        display: flex;
        background-color: rgb(0, 0, 0);
        width: 120px;
        height: 120px;
    }
}