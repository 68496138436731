.section-contac-us-1{
    height: 60vh;
    width: 100%;
    background: url('./../../resources/images/contactus.jpg');
    box-shadow: inset 0 0 0 1000px rgba(0,0,0,.6);
    background-size: cover ;
    background-position: center;
    background-attachment: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
}

.section-contac-us-header{
    color: white;
    font-size: 3em;
    font-family: AvenirNextBold;
    margin-left: 60%;
    padding: 0.5em;
    border:solid #ffffff 0.05em;
    text-transform: uppercase;
}

.section-contac-us-2{
    background-color: white;
    display: flex;
    color: white;
    margin-top: 0%;
}

.section-contac-us-2-left{
    background-color: #232323;
    width: 60%;
    min-width:60%;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.section-contac-us-2-right{
    background-color: #ffc000;
    width: 40%;
    min-width: 40%;
    flex: 1;
    display: flex;
    flex-direction: column;
}

.section-contac-us-2-text-container{
    display: flex;
    width: 40%;
    padding-bottom: 3em;
    margin-top: 5em;
    margin-left: 10%;
    border-bottom:solid transparent 0.3em;
}

.section-contac-us-2-text-container:hover{
    border-bottom:solid #000000 0.3em;
}

.section-contac-us-2-text-container.selected{
    border-bottom:solid #000000 0.3em;
}

.section-contac-us-2-header{
    color: black;
    font-weight: bold;
}

.section-contac-us-2-text{
    color: black;
}

.contac-us-location-icon{
    width: 14px;
    height: 19px;
    margin-right: 20px;
    margin-top: 20px;
}

.contact-us-get-in-touch-container{
    background-color: #282828;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8% 0;
}

.contact-us-get-in-touch{
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 85%;
}

.form-error{
    font-size: 1em;
    font-family: AvenirNextBold;
}

.msg-hidden{
    color: #282828;
}

.msg-showed{
    color: #ff0000;
}

.msg-success{
    color: #ffffff;
}

.contact-us-footer{
    min-height: 150px;
}

.contac-us-map{
    width: 100%;
    height: 500px;
}

@media only screen and (max-width: 1400px){
    .section-contac-us-2-text-container{
        width: 60%;
    }
}

@media only screen and (max-width: 1000px){
    .section-contac-us-2-text-container{
        width: 80%;
    }
    .section-contac-us-header{
        margin-left: 40%;
    }
}

@media only screen and (max-width: 480px){
    .section-contac-us-2{
        display: flex;
        flex-direction: column;
    }
    .section-contac-us-2-left{
        width: 100%;
    }
    .section-contac-us-2-right{
        width: 100%;
        min-width: 100%;
        display: flex;
        flex-direction: column;
    }
    .contact-us-get-in-touch{
        width: 100%;
    }
    .section-contac-us-header{
        margin-left: 0%;
    }
}