.services-section{
    width: 100%;
    min-height: 100vh;
    background-color: azure;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient(to bottom,#000000bb, #000000bb),url('./../../resources/images/ourservices.jpg');
    background-position: center;
    background-size:cover;
    background-attachment: fixed;
}

.services-info{
    color: white;
    width: 90%;
    max-width: 1100px;
    font-size: 1.2em;
    margin-top: 3%;
    margin-bottom: 3%;
    font-family: Book Antigua;
}

.services-header{
    color: #ffc000;
    font-size: 4em;
    font-family: AvenirNextBold;
    border-right:solid #ffc000 5px;
    padding-right: 25px;
}

.service-icon-container{
    width: 100%;
    max-width:1200px;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.service-item-icon{
    width: 140px;
    margin-bottom: 15px;
}
.service-item-icon:hover{
    transform: scale(1.1) translateY(-30px) !important;
}
.service-item{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
}

.service-item-subtitle{
    font-weight: bold;
}

@media only screen and (max-width: 1800px){
    .service-item-icon{
        width: 120px;
        margin-bottom: 15px;
    }
}
@media only screen and (max-width: 480px){
    .services-header{
        font-size: 2.5em;
        border-right:solid #ffc000 3px;
        padding-right: 25px;
    }
    .service-item-icon{
        width: 80px;
        margin-bottom: 15px;
    }
    .service-item{
        margin: 5%;
    }
    .services-info{
        font-size: 1em;
    }
    .services-section{
        background: linear-gradient(to bottom,#000000bb, #000000bb),url('./../../resources/images/ourservices.jpg');
        background-position: center;
        background-size:cover;
        background-attachment: none;
        padding: 15px;
    }
}
.header-section{
    background-color: #3c3c3c;
    height: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.header-section-header{
    color: #ffc000;
    font-family: AvenirNextUlL;
    border-right:solid #ffc000 2px;
    font-size: 4em;
    padding-right: 29px;
    margin-bottom: 1em;
    transition: all ease-in-out 2s;
}
.header-section-header:hover{
    border-right:solid #ffc000 4px;
    padding-right: 28px;
}
.header-section-header-primary{
    color: #ffc000;
    font-family: AvenirNextUlL;
    font-size: 4em;
    padding-right: 25px;
}


.header-section-second-header{
    border-right:solid #ffc000 3px;
    font-family: AvenirNextRegular;
    color: #ffc000;
    font-size: 4em;
    margin-top: -5px;
    padding-right: 25px;
    margin-bottom: 3%;
}
.header-section-info{
    color: #e6e6e6;
    font-family: AvenirNextRegular;
    max-width: 1100px;
    font-size: 1.5em;
}
@media only screen and (max-width: 1800px){
    .header-section-header-primary{
        font-size: 3em;
        padding-right: 25px;
    }
    .header-section-second-header{
        font-size: 3em;
    }
}
@media only screen and (max-width: 480px){
    .header-section-header{
        color: #ffc000;
        font-family: AvenirNextUlL;
        border-right:solid #ffc000 2px;
        font-size: 2.5em;
        padding-right: 10px;
        margin-bottom: 1em;
        transition: all ease-in-out 2s;
    }
    .header-section-info{
        font-size: 1.3em;
        padding: 0 15px;
    }
    .header-section-header-primary{
        font-size: 2.3em;
        padding-right: 0;
    }
    .header-section-second-header{
        border-right:solid #ffc000 3px;
        font-family: AvenirNextRegular;
        color: #ffc000;
        font-size: 2.5em;
        margin-top: -5px;
        padding-right: 25px;
        margin-bottom: 3%;
    }
}

.info-section{
    background-color: white;
    padding-top: 10%;
    padding-bottom: 10%;
}
.half-row{
    min-height: 50vh;
    display: flex;
}
.half-row-40{
    min-height: 50vh;

    display: flex;
}
.half-row-60{
    min-height: 50vh;
    display: flex;
}

.half-row.reverse{
    flex-direction: row-reverse;
}

.half-row-section{
    width: 50%;
    max-width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}



.go-public-buttons{    
    height: 30%;
    margin-bottom: 5%;
    text-align: left;
    display: flex;
}
.go-public-buttons-inner{
    background-color: rgba(0, 0, 0, 0.8);    
    height: 100%;
    width: 100%;
    max-width: 1000px;
    display: flex;
    flex-direction: column;
    border-radius: 15px;
}
.go-public-buttons-row{
    display: flex;
    justify-content: space-around;
}
.go-public-button{
    padding: 10px;
    margin: 10px 25px;
    height: 70px;
    display: flex;
    flex: 1;
    flex-direction: column;
    border-left: 3px solid;
    cursor: pointer;
    filter: opacity(50%);
}
.go-public-button:hover{
    filter: none;
}
.go-public-button.active{
    border-left: 3px solid #fec010;
    filter: none;
}
.go-public-info{
    height: 30%;
}
.go-public-info-inner{
    background-color: rgba(255, 255, 255, 0.9); 
    height: 100%;
    width: 100%;
    max-width: 1000px;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5%;
}


.factoring-img-section{
    background: url('./../../resources/images/refactoring.png');
    background-size: cover ;
    background-position: center;
}
.abl-img-section{
    background: url('./../../resources/images/abl.png');
    background-size: cover ;
    background-position: center;
}
.suplier-img-section{
    background: url('./../../resources/images/suplier.png');
    background-size: cover ;
    background-position: center;
}
.purchase-img-section{
    background: url('./../../resources/images/purchase.png');
    background-size: cover ;
    background-position: center;
}
.half-row-section-img{
    width: 140px;
    margin-top: 15px;
    margin-bottom: 15px;
}
.half-row-section-header{
    margin-bottom: 15px;
}
.half-row-section-info{
    max-width: 400px;
}
@media only screen and (max-width: 1800px){
    .half-row-section-img{
        width: 120px;
    }
}
@media only screen and (max-width: 480px){
    .half-row-section-img{
        width: 80px;
    }
    .half-row-section-header{
        margin-bottom: 15px;
        font-size: 1.5em;
        padding: 0 15px;
    }
    .half-row-section-info{
        max-width: none;
        padding: 0 15px;
        font-size: 1em;
    }
    .go-public-button{
        margin: 10px 5px;
    }
    .go-public-info-inner{
        height: 100%;
        padding: 5%;
    }
    .go-public-info{
        height: 40vh;
        margin-bottom: 5%;
    }
}
.why-go-public-section{
    height: 100vh;
    background: linear-gradient(to right,#00000062, #00000004),url('./../../resources/images/public.jpg');
    background-size: cover ;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.why-go-header-1{
    color: white;
    font-size: 4em;
    font-family: AvenirNextRegular;
}
.why-go-header-2{
    color: white;
    font-size: 6em;
    font-family: AvenirNextBold;
    margin-bottom: 5%;
}

.why-go-row{
    width: 100%;
    display: flex;
    flex-direction: column;
    width: 50%;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 30%;
}
.why-go-header-info{
    color: white;
    font-size: 1.8em;
    font-family: AvenirNextRegular;
    max-width: 1000px;
}
.row-public{
    justify-content: flex-start;
    max-width: 1100px;
}
.row-public-header-1{
    font-family: AvenirNextRegular;
    color:#ffffff;
    font-size: 1.7em;
    margin: 0;
}
.row-public-header-2{
    font-family: AvenirNextBold;
    color:#ffffff;
    font-size: 1.8em;
    margin-bottom: 5%;
    margin-top: 0;
}
.row-public-header-info{
    font-family: AvenirNextRegular;
    color:#5a5a5a;
    font-size: 1.2em;
}
.row-public-inner{
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 500px;
    height: 100%;
}
.why-go-public-left{
    width: 40%;
}
.why-go-public-right{
    width: 60%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.vanish-strip{
    background-image: linear-gradient(to right, rgb(54, 54, 54), rgb(92, 92, 92));
    height: 5vh;
}

@media only screen and (max-width: 1800px){
    .why-go-header-1{
        font-size: 3em;
    }
    .why-go-header-2{
        font-size: 5em;
    }
    .why-go-header-info{
        font-size: 1.5em;
    }
}

@media only screen and (max-width: 480px){
    .why-go-public-section{
        height: auto;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .why-go-public-left{
        width: 100%;
    }
    .why-go-row{
        padding: 15px;
        margin-left: 0;
        width: 100%;
    }
    .why-go-header-1{
        font-size: 2.5em;
    }
    .why-go-header-2{
        font-size: 4em;
        margin-top: -.1em;
    }
    .why-go-public-right{
        width: 100%;
    }
    .row-public-header-1{
        font-size: 1em;
        margin: 0;
    }
    .row-public-header-2{
        font-size: 1.1em;
    }
    .row-public-header-info{
        font-size: 1.1em;
    }
}

.half-public{
    max-width: 1300px;
    margin: 0 auto;
}
/*           Benefits            */
.benefits-row{
    background: url('./../../resources/images/challenge.jpg');
    background-size: cover;
    background-position: 50% 60%;
    display: flex;
    flex-wrap: wrap;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 100vh;
}

.benefits-car-base{
    height: 100vh;
    width: 100%;
    position: relative;
}
.right-arrow{
    position: absolute;
    right: 5%;
    top: 400px;
    cursor: pointer;
    transition: all ease-in .08s;
    width: 60px;
}
.right-arrow.hidde-arrow, 
.left-arrow.hidde-arrow{
    opacity: 0.2;
    pointer-events: none;
}

.left-arrow{
    position: absolute;
    left: 5%;
    top: 400px;
    cursor: pointer;
    transition: all ease-in .2s;
    width: 60px;
}

.right-arrow:hover,
.left-arrow:hover{
    transform: scale(1.2);
}

.cube{
    max-width: 30%;
    position: absolute;
    left: 10%;
    top: 280px;
    transition: transform ease-out 0.5s;
}
.cube:hover{
    transform: scale(1.1);
}
.hide-cube{
    visibility: hidden;
}
.benefits-info-1{
    position: absolute;
    top: 30%;
    left: 50%;
    max-width: 400px;
}
.benefits-info-1.benefits-info-hidden{
    visibility: hidden;
}
.benefits-header-container{
    display: flex;
}

.benefits-header-wraper{
    padding-right: 15px;
    border-right: solid 3px #3c3c3c;
}
.benefits-header-tittle{
    color:#3c3c3c;
    font-family: AvenirNextBold;
    font-size: 3.3em;
    margin: 0;
}
.benefits-row-text{
    color: white;
    font-family: AvenirNextRegular;
    margin-top: 2em;
    font-size: 1.5em;
}
.benefits-info-1-alt{
    position: absolute;
    top: 10%;
    left: 45%;
    max-width: 40%;
    height: auto;
}

@media only screen and (max-width: 1400px){
    .benefits-row-right{
        max-height: 100%;
    }
    .benefits-row-left{
        max-height: 100%;
    }
    .benefits-info-1-alt{
        left: 40%;
        max-width: 45%;
        top: 5%;
        max-height: 90%;
        overflow-y:scroll;
    }
    .cube{
        max-width: 25%;
    }
    .benefits-info-1{
        left: 45%;
    }
}

@media only screen and (max-width: 1200px){
    .benefits-row-right{
        max-height: 100%;
    }
    .benefits-row-left{
        max-height: 100%;
    }
    .benefits-info-1-alt{
        left: 35%;
        max-width: 50%;
        top: 5%;
        max-height: 90%;
        overflow-y:scroll;
    }
    .cube{
        max-width: 20%;
    }
    .benefits-info-1{
        left: 40%;
    }
}

@media only screen and (max-width: 1000px){
    .benefits-info-1{
        left: 35%;
    }
}

@media only screen and (max-width: 480px){
    .benefits-row-right{
        height: 100%;
    }
    .benefits-row-left{
        height: 100%;
    }
    .benefits-info-1{
        position: absolute;
        top: 40%;
        left: 0%;
        width: 100%;
        max-width: none;
        padding: 15px;
    }
    .cube{
        max-width: 50%;
        position: absolute;
        left: 25%;
        top: 80px;
        transition: transform ease-out 0.5s;
    }
    .benefits-header-tittle{
        font-size: 2.3em;
    }
    .benefits-row-text{
        font-size: 1.2em;
    }
    .benefits-info-1-alt{
        position: absolute;
        top: 30%;
        left: 5%;
        right: 5%;
        max-width: 100%;
        height: 70%;
        overflow: unset;
    }
    .right-arrow{
        right: 0%;
        top: 200px;
        width: 40px;
    }
    .left-arrow{
        left: 0%;
        top: 200px;
        width: 40px;
    }
    .benefits-inner-row{
        height: 100%;
        min-width: 400px;
        display: flex;
        flex-direction: column;
        overflow-x:scroll;
    }
    .challenges-inner-row{
        height: 100%;
        min-width: 400px;
        display: flex;
        flex-direction: column;
        overflow-x:scroll;
    }
    .benefits-full-item{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-top: 5px;
        margin-bottom: 5px;
    }
}

/*           Benefits            */

.yellow-back{
    background-color: #ffc000;
    display: flex;
    flex-direction: column;
}

.first-white-separator{
    min-height: 20vh;
    background-color: white;
    border-radius: 75px;
    margin-top: -10vh;
    padding-top: 20vh;
    padding-bottom: 10vh;
}
.second-white-separator{
    min-height: 20vh;
    background-color: white;
    border-bottom-right-radius: 75px;
    border-bottom-left-radius: 75px;
    margin-top: -10vh;
    z-index: 0;
}

.benefits-info-1-alt.benefits-info-hidden{
    visibility: hidden;
}

.benefits-info-row{
    background-color: #012f0140;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border-radius: 100px;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 5%;
    padding-bottom: 5%;
    z-index: 10;
}

.benefits-header{
    color: white;
    text-align: center;
    font-family: AvenirNextBold;
    font-size: 3em;
}

@media only screen and (max-width: 1800px){
    .benefits-header{
        font-size: 2.5em;
    }
}
@media only screen and (max-width: 480px){
    .benefits-header{
        font-size: 2em;
    }
}

.benefits-inner-row{
    width: 100%;
    max-width: 1200px;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    padding-top: 5%;
}

.benefits-info-item{
    width: 25%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    margin: 1%;
    color: white;
}

.benefit-info-text{
    color: white;
    font-family: AvenirNextRegular;
    font-size: 1.em;
    max-width: 250px;
}

.benefit-info-icon{
    padding-bottom: 1em;
    transition: scale ease .2s;
}
.benefit-info-icon:hover{
    transform: scale(1.2);
}

.challenges-info-row{
    background-color: #fd012840;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border-radius: 100px;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 5%;
    padding-bottom: 5%;
    z-index: 10;
}

.challenges-inner-row{
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.benefits-full-item{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 15px;
}

.benefits-item{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 45%;
    margin-top: 15px;
    margin-bottom: 15px;
}

.benefits-full-item-description{
    color: white;
    width: 90%;
    font-size: 1em;
    font-family: AvenirNextRegular;
}


.public-market-info-row{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient(to bottom,#000000a0, #000000c0),url('./../../resources/images/publicmarket.jpg');
    background-position: center;
    background-size:cover;
    background-attachment: fixed;
}

.public-market-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.public-market-buttons{
    display: flex;
}

.public-market-buttons-inner{
    background-color: rgba(255, 255, 255, 0.9);
    width: 100%;
    max-width: 1000px;
    display: flex;
    flex-direction: column;
    border-radius: 15px;
    padding-bottom: 1%;
    margin-bottom: 5%;
    padding: 10px;
}
.public-market-buttons-row{
    display: flex;
    justify-content: space-around;
}
@media only screen and (max-width: 1800px){
    .public-market-buttons-row{
        padding-bottom: 2em;
    }
}
.public-market-button{
    padding-left: 10px;
    margin: 10px 25px;
    height: 50px;
    flex: 1;
    display: flex;
    flex-direction: column;
    border-left: 3px solid;
    cursor: pointer;
    filter: opacity(50%);
    text-align: left;
}
.public-market-button:hover{
    filter: none;
}
.public-market-button.active{
    filter: none;
    border-left: 3px solid #fec010;
}
.public-market-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 30vh;
    margin-bottom: 4%;
}
.public-market-part-1{
    display: flex;
    margin: 3% 0;
}
.public-market-info-left{
    width: 40%;
    max-width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.public-market-info-right{
    width: 60%;
    max-width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.public-market-info-inner{
    background-color: rgba(255, 255, 255, 0.9); 
    height: 100%;
    width: 100%;
    max-width: 1000px;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5%;
    overflow: scroll;
}
.public-market-header-container{
    align-self: start;
}
.public-market-header-1{
    font-size: 4.2em;
    color: #ffffff;
    font-family: AvenirNextRegular;
    margin: 0;
}
.public-market-header-2{
    margin: 0;
    font-size: 3.5em;
    color: #ffffff;
    font-family: AvenirNextBold;
}

.public-market-info-text{
    max-width: 700px;
    font-size: 1.5em;
    font-family: AvenirNextRegular;
    color: #ffffff;
}

.public-market-info-row-first-section{
    background-color: thistle;
    width: 100%;
    display: flex;
}

.public-market-item{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    max-width: 1100px;
    margin-top: 15px;
}
.grey-line{
    width: 400px;
    align-self: flex-start;
    border: solid 2px #5a5a5a;
    margin-bottom: 5em;
    margin-top: 2em;
}
.public-market-item-header-container{
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
}
.pmiheader-1{
    font-family: AvenirNextRegular;
    font-size: 1.5em;
    color: #252525;
    margin: 0;
}
.pmiheader-2{
    font-family: AvenirNextBold;
    font-size: 1.5em;
    color:#353535;
    margin-bottom: 5%;
    margin-top: 0;
}

.public-market-item-info{
    font-size: 1.4em;
    max-width: 1200px;
    align-self: center;
    color: #3c3c3c;
    font-family: AvenirNextRegular;
    text-align: justify;
    padding-top: 5px;
}

@media only screen and (max-width: 1800px){
    .public-market-info-text{
        max-width: 600px;
        font-size: 1.3em;
    }
    .public-market-header-1{
        font-size: 2.5em;
        color: #ffffff;
        font-family: AvenirNextRegular;
    }
    .public-market-header-2{
        font-size: 2.5em;
        color: #ffffff;
        font-family: AvenirNextBold;
    }
    .pmiheader-1{
        font-size: 2em;
    }
    .pmiheader-2{
        font-size: 2em;
    }
    .public-market-item-info{
        font-size: 1.2em;
        max-width: 1100px;
    }
    .benefits-info-item{
        display: flex;
        flex-wrap: wrap;
    }
}
@media only screen and (max-width: 480px){
    .public-market-info-row{
        background: linear-gradient(to bottom,#1414146b, #14141460),url('./../../resources/images/publicmarket.jpg');
        background-position: center;
        background-size:cover;
        background-attachment: none;
    }
    .benefit-info-icon{
        margin: 5px;
        transition: scale ease .2s;
        width: 40px;
    }
    .benefits-full-item-description{
        color: white;
        width: 90%;
        font-size: 1em;
        font-family: AvenirNextRegular;
    }
    .benefits-item{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-top: 5px;
        margin-bottom: 5px;
    }
    .benefits-full-item{
        margin-top: 5px;
        margin-bottom: 5px;
    }
    .benefits-info-item{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        align-items: center;
        margin: 1%;
        color: white;
    }
    .public-market-part-1{
        display: flex;
        flex-direction: column;
        margin: 3% 0;
        padding: 15px;
    }
    .public-market-info-text{
        max-width: none;
        width: 100%;
        font-size: 1.5em;
        font-family: AvenirNextRegular;
        color: #e7e7e7;
    }
    .public-market-info-left{
        width: 100%;
        max-width: 100%;
    }
    .public-market-info-right{
        width: 100%;
        max-width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .public-market-info-text{
        max-width: 700px;
        font-size: 1.2em;
    }
    .pmiheader-1{
        font-size: 1em;

    }
    .pmiheader-2{
        font-size: 1em;
    }
    .public-market-button{
        padding-left: 10px;
        margin: 10px 10px;
        height: 50px;
        flex: 1;
        display: flex;
        flex-direction: column;
        border-left: 3px solid;
        cursor: pointer;
        filter: opacity(50%);
        justify-content: center;
    }
    .public-market-buttons-inner{
        max-width: none;
        display: flex;
        flex-direction: column;
        border-radius: 15px;
        padding-bottom: 1%;
        margin-bottom: 5%;
        padding: 10px;
    }
    .public-market-item-info{
        font-size: 1em;
    }
    .public-market-info {
        height: 40vh;
        margin-bottom: 4%;
    }

    .public-market-header-2,
    .public-market-header-1{
        color: white;
    }
}



.apo-model-section{
    height: 80vh;
    background-color: white;
    position: relative;
}
.advantages-info-rarrow{
    position: absolute;
    right: 2%;
    top: 50%;
    transition: all ease-in .2s;
    width: 60px;
}
.advantages-info-larrow{
    position: absolute;
    left: 2%;
    top: 50%;
    transition: all ease-in .2s;
    width: 60px;
}

.advantages-info-rarrow:hover,
.advantages-info-larrow:hover{
    transform: scale(1.1);
}
.advantages-list-style:hover{
    color: var(--inv);
}
.advantages-list-text{
    color: black;
}
.apo-model-info-row{
    background-size: cover;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.apo-model-info-title{
    background: url('./../../resources/images/gold.png');
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 60%;
    margin: 20px 0;
    clip-path: polygon(0 0, 100% 0, 60% 100%, 0% 100%);
}
.apo-model-item{
    margin-left: -12%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    padding: 2%;
    clip-path: polygon(20% 0%, 100% 0, 100% 100%, 0% 100%);
}

.apo-model-info-inner{
    background-color: #232323;
    width: 100%;
    height: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
}
.apo-model-item-inner{
    display: flex;
    flex-direction: column;
    max-width: 1100px;
}
.apo-model-item-title-1{
    text-align: left;
    font-size: 6em;
    color: #f1f1f1;
    font-family: AvenirNextBold;
    margin: 0;
    margin-right: 10%;
}
.apo-model-item-title-2{
    text-align: left;
    font-size: 7em;
    color: #f1f1f1;
    font-family: AvenirNextRegular;
    margin: 0;
    margin-right: 10%;
    margin-bottom: 3%;
    text-transform: uppercase;
}

.apo-model-item-info{
    font-size: 1.3em;
    color: #232323;
    font-family: AvenirNextRegular;
    max-width: 850px;
}

.ml-10{
  margin-left: 10%;
}
.ml-5{
    margin-left: 10%;
}
.ml-minus-5{
    margin-left: 10%;

}
.ml-minus-10{
    margin-left: 10%;

}
@media only screen and (max-width: 1800px){
    .apo-model-item-inner{
        max-width: 1000px;
    }
    .apo-model-item-title-1{
        font-size: 2.5em;
    }
    .apo-model-item-title-2{
        font-size: 3.2em;
        margin-bottom: 2%;
    }
    .apo-model-item-info{
        font-size: 1.1em;
    }
}
@media only screen and (max-width: 480px){
    .apo-model-item-title-1{
        font-size: 1.5em;
    }
    .apo-model-item-title-2{
        font-size: 2em;
        margin-bottom: 2%;
    }
    .apo-model-item-info{
        font-size: 0.75em;
    }
    .ml-10{
        margin-left: 20%;
    }
    
    .ml-5{
        margin-left: 15%;
    }
    .advantages-list-text{
        color: black;
        font-size: .8em;
    }
    .advantages-info-rarrow{
        z-index: 2;
        opacity: 0.6;
        width: 40px;
        top: 90%;
    }
    .advantages-info-larrow{
        opacity: 0.6;
        width: 40px;
        top: 90%;
    }
}
.advantages-info-row{
    padding: 5% 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.advantages-info-title-1{
    font-size: 1.7em;
    font-family: AvenirNextRegular;
}
.advantages-info-title-2{
    font-size: 2.5em;
    font-family: AvenirNextBold;
    color: #4e4e4e;
}
.advantages-info-list{
    margin: 5% 0;
    font-size: 1.5em;
    font-family: AvenirNextRegular;
    max-width: 1000px;
    color: #4a4a4a;
}

.info-1{
    margin-left: 90%;
}

.merger-acquisitions-info-row{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 90vh;
}
.merger-acquisitions-inner{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}
.merger-acquisitions-item{
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 1100px;
    margin-top: 15px;
    margin-bottom: 15px;
    height: 25vh;
    padding: 15px;
}
.merger-acquisitions-item-title-1{
    align-self: left;
    font-size: 2.5em;
    color: #505050;
    font-family: AvenirNextRegular;
}

.merger-acquisitions-item-title-2{
    align-self: left;
    font-size: 2.3em;
    color: #505050;
    font-family: AvenirNextBold;
}

.merger-acquisitions-item-info{
    font-size: 1.2em;
    max-width: 1200px;
    align-self: center;
    color: #505050;
    font-family: AvenirNextRegular;
    margin: 2% 0;
}
@media only screen and (max-width: 1800px){
    .advantages-info-list{
        margin: 5% 0;
        font-size: 1.3em;
        max-width: 900px;
    }
    .merger-acquisitions-item{
        max-width: 1000px;
    }

    .merger-acquisitions-item-title-1{
        font-size: 2em;
    }
    .merger-acquisitions-item-title-2{
        align-self: left;
        font-size: 2em;
        color: #505050;
        font-family: AvenirNextBold;
    }
}

@media only screen and (max-width: 480px){
    .advantages-info-title-1{
        font-size: 1.5em;
    }
    .advantages-info-title-2{
        font-size: 2em;
    }
    .merger-acquisitions-item-title-1{
        font-size: 2em;
    }
    
    .merger-acquisitions-item-title-2{
        font-size: 2em;
    }
    .merger-acquisitions-item-info{
        font-size: 1em;
        max-width: 1200px;
        align-self: center;
        color: #505050;
        font-family: AvenirNextRegular;
        margin: 2% 0;
    }
}

.merger-acquisitions-right-arrow{
    position: absolute;
    top: 40%;
    right: 5%;
    cursor: pointer;
    transition: all ease-in .08s;
    max-height: 7.5%;
    width: 40px;
}

.merger-acquisitions-left-arrow{
    position: absolute;
    top: 40%;
    left: 5%;
    cursor: pointer;
    transition: all ease-in .2s;
    max-height: 7.5%;
    width: 40px;
}

.merger-acquisitions-right-arrow:hover,
.merger-acquisitions-left-arrow:hover{
    transform: scale(1.2);
}

.merger-acquisitions-indicators {
    bottom: 20%;
    display: flex;
    flex-direction: row;
}

.circle-black {
    background: rgb(0, 0, 0);
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 5px;
    margin-left: 5px;
}

.circle-black:hover{
    transform: scale(1.2);
}

.circle-gray {
    background: rgb(153, 153, 153);
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 5px;
    margin-left: 5px;
}

.circle-gray:hover{
    transform: scale(1.2);
    background: rgb(59, 59, 59);
}

.red{
    background-color: tomato;
}

.investor-relation-services-row{
    background-color: #3c3c3c;
    padding: 5% 0;
}

.investor-relation-services-image{
    background: url('./../../resources/images/inverstor.png');
    background-size: cover;
    display: flex;
    justify-content: center;
}

.investor-relation-services-inner{
    max-width: 1100px;
    padding: 5% 0px;
}
.investor-relation-header{
    color: #ffc000;
    font-family: AvenirNextUlL;
    font-size: 4em;
    padding-right: 25px;
}

.investor-relation-header-2{
    font-family: AvenirNextRegular;
    color: #ffc000;
    font-size: 4em;
    margin-top: -5px;
    max-width: 10em;
    padding-right: 25px;
    margin-bottom: 3%;
    border-right:solid #ffc000 .06em;
}
@media only screen and (max-width: 1800px){
    .investor-relation-header{
        font-size: 3em;
        padding-right: 20px;
    }
    .investor-relation-header-2{
        font-size: 3em;
        max-width: 10em;
        padding-right: 20px;
        margin-bottom: 2%;
    }
    .header-section-info{
        font-size:1.3em;
        max-width: 900px;
    }
}
@media only screen and (max-width: 480px){
    .investor-relation-header{
        font-size: 2.5em;
        padding-left: 15px;
    }
    .investor-relation-header-2{
        font-size: 2.5em;
        padding-left: 15px;
    }
    .merger-acquisitions-left-arrow{
        left: 0%;
        top: 45%;
        width: 20px;
    }
    .merger-acquisitions-right-arrow{
        right: 0%;
        top: 45%;
        width: 20px;
    }
}

.public-manage-row{
    background-color: black;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: AvenirNextRegular;
    padding-top: 45px;
    padding-bottom: 15px;
    padding-right: 15px;
    padding-left: 15px;
}

.public-manage-info{
    max-width: 1100px;
    font-size: 1.7em;
}

.we-help-you{
    background: url('./../../resources/images/black.png');
    background-size: cover;
    color: white;
    min-height: 80vh;
    max-height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom: solid 4px #c3a248;
}
.we-help-section{
    padding: 5% 0 10%;
}
.we-help-you-tittle-1{
    font-size: 3em;
    font-family: AvenirNextRegular;
    margin-top: 8%;
}
.we-help-you-tittle-2{
    font-size: 3em;
    font-family: AvenirNextBold;
}

.we-help-you-inner{
    background-color: white;
    color: black;
    max-width: 1100px;
    border-radius: 5px;
    border: solid 5px #c3a248;
    padding: 4em;
    margin-top: 1.6em;
}
.help-you-inner-subheader{
    font-family: AvenirNextBold;
    font-size: 1.5em;
}
.help-you-inner-info{
    font-family: AvenirNextRegular;
    font-size: 1.5em;
    margin-bottom: 1em;
    color: #3c3c3c;
}

.get-in-touch{
    background-color: #282828;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8% 0;
}
.get-in-touch-header{
    text-align: center;
    font-family: AvenirNextRegular;
    text-transform: uppercase;
    margin-bottom: 2%;
}

.touch-form{
    width: 100%;
    max-width: 800px;
    display: flex;
}
.touch-form-left{
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 5px;
}
.touch-form-right{
    width: 50%;
    padding: 5px;
}
.input-custom{
    background-color: rgba(255, 255, 255, 0.404);
    color: white;
    border: none;
    width: 100%;
    height: 40px;
    padding: 1px 5px;
    border-radius: 5px;
    font-family: AvenirNextRegular;
    margin: 5px;
}
.input-custom-area{
    background-color: rgba(255, 255, 255, 0.404);
    color: white;
    border: none;
    width: 100%;
    padding: 5px 5px;
    border-radius: 5px;
    font-family: AvenirNextRegular;
    height: 90%;
    margin-top: 5px;
}

textarea {
    resize: none;
}

.submit-button{
    border: 0;
    margin: 0;
    margin-top: 2%;   
}

@media only screen and (max-width: 1800px){
    .public-manage-info{
        max-width: 1100px;
        font-size: 1.5em;
    }

    .we-help-you-tittle-1{
        font-size: 2.5em;
        margin-top: 8%;
    }
    .we-help-you-tittle-2{
        font-size: 2.5em;
        font-family: AvenirNextBold;
    }
    .help-you-inner-info{
        font-size: 1.3em;
        margin-bottom: 1em;
        color: #3c3c3c;
    }
    .we-help-section{
        padding: 5% 0 15%;
    }
}
@media only screen and (max-width: 480px){
    .public-manage-info{
        font-size: 1.3em;
    }
    .we-help-you{
        max-height: none;
    }
}



/*
Seperator section
*/
.separator-section{
    display: block;
    margin-top: -3em;
    height: 6em;
    color: white;
}
.under-separator{
    margin-top: -3em;
}

.screen-75{
    margin-left: calc(75vw - 7em);
}
/*... ya se, que sad que no se pueda poner una funcion :'c*/
.screen-65{
    margin-left: calc(65vw - 7em);
}
.screen-50{
    margin-left: calc(50vw - 7em);
}

@media only screen and (max-width: 1800px){
    .separator-section{
        display: block;
        margin-top: -2.5em;
        height: 5em;
        color: white;
    }
    .under-separator{
        margin-top: -2.5em;
    }
    
    .screen-75{
        margin-left: calc(75vw - 5em);
    }
    /*... ya se, que sad que no se pueda poner una funcion :'c*/
    .screen-65{
        margin-left: calc(65vw - 5em);
    }
    .screen-50{
        margin-left: calc(50vw - 5em);
    }
}

.footer{
    width: 100%;
    min-height: 300px;
    background-color: black;
    color: #7e7e7e;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.footer-info{
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.direction-container{
    display: flex;
    align-items: center;
}
.footer-copy{
    display: flex;
    justify-content: space-around;
}
.location-icon{
    width: 15px;
    margin-right: 10px;
}
.footer-logo{
    width: 100px;
    height: auto;
}

.location-label-footer{
    margin: 0;
}
.direction-label-footer{
    margin: 0;
}

.footer-final-link{
    margin-left: 15px;
    margin-right: 15px;
    transition: all .8s ease-in;
}
.footer-final-link:hover{
    color: white;
}
.footer-list-section{
    border-left: 1px #5a5a5a solid;
    padding-left: 35px;
}
.footer-list-style:hover{
    color: var(--inv);
}
.footer-list-text{
    color: #7e7e7e;
}

@media only screen and (max-width: 480px){
    .footer-info{
        min-width: initial;
        flex-direction: column;
    }
    .footer-logo{
        width: 80px;
        margin-bottom: 10px;
        margin-top: 10px;
    }
    .direction-container{
        display: flex;
        align-items: center;
        width: 100%;
        padding-left: 10%;
        margin-bottom: 15px;
    }
    .footer-list-section{
        display: none;
    }
    .footer-copy{
        flex-direction: column;
    }
}

/*    Special parts *W*     */

.yellow-indicator{
    padding-bottom: 10px;
    position: relative;
    transition: all 5s ease-out;
    transition-delay: .6s;
}
.yellow-indicator:hover{
    transition-delay: .6s;
}

.yellow-indicator::after{
    position: absolute;
    content: '';
    border: 1px solid var(--inv);
    animation: encogiendo 1s ease forwards ;
}
.yellow-indicator:hover::after{
    border: 1px solid var(--inv);
    transition: all ease 1s;
    animation: creciendo 1s ease forwards ;
}

@keyframes creciendo {
    1%   {width: 0%; }
    100% {width: 100%;}
  }
@keyframes encogiendo {
    1%   {width: 100%;}
    100% {width: 0%; }
  }

.black-indicator{
    position: relative;
    transition: all 5s ease-out;
    transition-delay: .6s;
    padding: 0 10px;
}

.black-indicator::after{
    content: none;
}

.black-indicator::before{
    position: absolute;
    content: '';
    border: 1px solid black;
    animation: encongiendo-vertical 1s ease forwards ;
}

.black-indicator::before{
    top: 0;
    right: 0;
}
.black-indicator::after{
    top: 0;
    left: 0;
}

.black-indicator:hover::before,
.black-indicator:hover::after{
    border: 1px solid black;
    transition: all ease 1s;
    animation: creciendo-vertical 1s ease forwards ;
}

@keyframes creciendo-vertical {
    1%   {height: 0%; }
    100% {height: 100%;}
}
@keyframes encongiendo-vertical {
    1%   {height: 100%; }
    100% {height: 0%;}
}


.animation-left-in{
    animation: moveInLeft 1s ease 0s 1 normal forwards running;
}
.animation-left-out{
    animation: moveOutLeft 1s ease 0s 1 normal forwards running;
}
.animation-right-in{
    animation: moveInRight 1s ease 0s 1 normal forwards running;
}
.animation-right-out{
    animation: moveOutRight 1s ease 0s 1 normal forwards running;
}
@keyframes moveInLeft{
    0%{
        opacity: 0;
        transform: translateX(-300px);
    }
    100%{
        opacity: 1;
        transform: translateX(0);
    }
}
@keyframes moveOutLeft{
    0%{
        opacity: 1;
        transform: translateX(0);
    }
    100%{
        opacity: 0;
        transform: translateX(-300px);
    }
}
@keyframes moveInRight{
    0%{
        opacity: 0;
        transform: translateX(300px);
    }
    100%{
        opacity: 1;
        transform: translateX(0);
    }
}
@keyframes moveOutRight{
    0%{
        opacity: 1;
        transform: translateX(0);
    }
    100%{
        opacity: 0;
        transform: translateX(300px);
    }
}

.non-visible{
    display: none;
}

.non-events{
    pointer-events: none;
}

.form-error{
    font-size: 1em;
    font-family: AvenirNextBold;
}

.msg-hidden{
    color: #282828;
}

.msg-showed{
    color: #ff0000;
}

.msg-success{
    color: #ffffff;
}