.singlePhoto-container-no-photo{
    width: 210px;
    display: flex;
    flex-direction: column;
    flex: 1;
    transition: all .1s;
    filter: grayscale(80%);
    min-height: 200px;
}

.singlePhoto-container-no-photo.selected{
    width: 210px;
    display: flex;
    flex: 1;
    flex-direction: column;
    box-shadow: 0 10px 20px rgba(0, 0, 0, .9);
    filter: none;
}

.singlePhoto-container-no-photo:hover{
    width: 210px;
    display: flex;
    flex-direction: column;
    box-shadow: 0 10px 20px rgba(0, 0, 0, .7);
    transition: all .1s;
    filter: none;
}

.singlePhoto-container-separator{
    height: 8px;
    width: 100%;
    background-color: #ffc000;
    margin-top: 1px;
    margin-bottom: 1px;
}

.singlePhoto-text-container-no-photo{
    background-color: white;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-content: left;
    justify-content: space-around;
    padding-top: 5%;
    padding-bottom: 5%;
    padding-right: 5%;
    padding-left: 5%;
    flex: 1;
}

.single-name-no-photo{
    color: #5a5a5a;
    margin: 0;
    font-size: 1.4em;
    font-family: AvenirNextBold;
    text-align: left;
    margin-bottom: 15px;
}

.single-position-no-photo{
    color: #5a5a5a;
    margin: 0;
    text-align: left;
    font-size: 1.2em;
    font-family: AvenirNextRegular;
}

@media only screen and (max-width: 1800px){
    .single-name-no-photo{
        font-size: 1.2em;
        margin-bottom: 12px;
    }
    .single-position-no-photo{
        font-size: 1em;
        font-family: AvenirNextRegular;
    }
}
@media only screen and (max-width: 1200px){
    
}