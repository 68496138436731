body {
	--def: white; 	
	--inv: #ffc000;
}

.navbar{
    width: 100%;
    margin: 0; 
    padding-top: 15px;
    padding-bottom: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    position: fixed;
    top: 0;
    background-color: rgba(0,0,0,.5);
    text-align: left;
    z-index: 100;
    transition: all ease-in .2s;
}

.withBackground{
    background-color: rgba(0,0,0,.5);
}

.navbar:hover{
    background-color: rgba(0,0,0,.5);
}

svg {
    display: block;
    width: 100%;
    height: 90px;
  }

.submenu-icon{
    margin: 5px;
}
.menu-section{
}
.menu-section ul{
    list-style:none;
	position:relative;
	float:left;
	margin:0;
	padding:0
}
.menu-section ul a{
    color:rgb(255, 255, 255);
    display:block;
	text-decoration:none;
    font-size:1.1em;
    text-transform: uppercase;
	padding:10px 15px;
	font-family:"AvenirNextRegular","Helvetica Neue";
    transition: all .3s ease-out;
}
.menu-section ul a::before,
.menu-section ul a::after{
	content: '';
	position: absolute;	
	transition: inherit;
	z-index: -1;
}
.menu-section ul a:hover {
	color: var(--def);
    transition-delay: .6s;
}

.menu-section ul a:hover:before {
	transition-delay: 0s;
}

.menu-section ul a:hover:after {
	transition-delay: .4s;
}


.from-middle:before {
	top: 0;
	left: 50%;
	height: 100%;
	width: 0;
	border: 2px solid var(--inv);
	border-left: 0;
	border-right: 0;
}

.from-middle:after {
	bottom: 0;
	left: 0;
	height: 0;
	width: 100%;
}

.from-middle:hover:before {
	left: 0;
	width: 100%;
}

.from-middle:hover:after {
	top: 0;
	height: 100%;
}

.menu-section ul{
    margin-right: 15px;
}


.menu-section ul li{
    position:relative;
	float:left;
	margin:0;
	padding:0
}

.menu-section ul ul
{
	display:none;
	position:absolute;
	top:100%;
	left:0;
	background:rgba(0, 0, 0, 0.384);
	padding:0
}
.menu-section ul ul li:hover{
    background-color: black;
}
.menu-section ul ul li
{
	float:none;
    width:200px;
    transition:all .4s ease-out ;
}

.menu-section ul ul a
{
	line-height:120%;
    padding:10px 15px;
    text-transform: none;
    color: #ffffff;
    margin-top: 0px;
    margin-bottom: 0px;
}

.menu-section ul ul a:hover{
    color: #ffb400;
}
.menu-section ul ul ul
{
	top:0;
	left:100%
}

.menu-section ul li:hover > ul
{
	display:block
}


.btn,
.btn:link,
.btn:visited{
    text-transform: uppercase;
    text-decoration: none;
    padding: 15px 40px;
    display: inline-block;
    border-radius: 100px;
    transition: all .2s;
    position: relative;
    font-family: AvenirNextBold;
    margin-right: 20px;
}

.btn:hover{
    transform: translateY(-3px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, .2);
}
.btn:active{
    transform: translateY(-1px);
    box-shadow: 0 5px 20px rgba(0, 0, 0, .2);
}

.btn::after{
    content: "";
    display: inline-block;
    height: 100%;
    width: 100%;
    border-radius: 100px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    transition: all .4s;
}

.btn-white{
    background-color: #ffc000;
    color: rgb(0, 0, 0);
}

.btn-white::after{
    background-color: #ffc000;
}

.btn:hover::after{
    transform: scaleX(1.4) scaleY(1.6);
    opacity: 0;
}
.btn-animated{
    animation: moveInTop 1s ease-out;
    animation-fill-mode: backwards;
}


.logo-header{
    margin-left: 25px;
    margin-top: 15px;
    transition: all .2s ease-in;
    width: 240px;
    cursor: pointer;
}


.topnav {
    overflow: hidden;
    background-color: #333;
    position: fixed;
    display: none;
    top: 0;
    z-index: 100;
    width: 100vw;
    min-height: 60px;
}
.topnav #myLinks {
    display: none;
}

.showLinks{
    display: block!important;
}
.topnav a {
    color: white;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 17px;
    display: block;
}
.topnav a.icon {
  background: black;
  display: block;
  position: absolute;
  min-height: 60px;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
}

.topnav a:hover {
    background-color: #ddd;
    color: black;
  }
  
  .topnav>.active {
    background-color: #000;
    min-height: 60px;
    color: white;
    font-size: 1.5em;
  }

  .navbar-link{
    color: #ffc000!important;
  }

@media only screen and (max-width: 1800px){
    .menu-section ul a{
        font-size:1em;
    }

    .button-header-text{
        font-size: .8em;
        letter-spacing: 0px;
    }
    .button-header-text a{
        color: black;
        text-decoration: none;
    }
}
@media only screen and (max-width: 480px){
    .navbar{
        display: none;
    }
    .topnav{
        display: block;
        background-color: rgba(51, 51, 51, 0.911);
    }
}


@keyframes moveInTop{
    0%{
        opacity: 0;
        transform: translateY(-30px);
    }
    100%{
        opacity: 1;
        transform: translate(0px);
    }
}